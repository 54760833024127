<template>
  <div class="user-card">
    <div class="avator">
      <div class="line outline">
        <div class="line innerline">
          <div :class="['line image', { active: Forte }]">
            <img
              v-if="Forte"
              class="value"
              src="../static/images/makeanew/metamask-fox.svg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="username">
      <template v-if="Forte">
        <span>{{ identify($store.state.address) }}</span>
        <svg-icon
          iconClass="copy"
          class="copy"
          @click="copy($store.state.address)"
        ></svg-icon>
      </template>
      <template v-else>
        <div class="not-login" @click="Globaland">Connect Wallet</div>
      </template>
    </div>
    <div class="usergrid" v-if="Forte">
      <div class="airdrops">
        <div class="unit">Airdrops</div>
        <div class="value" @click="toFollow">{{ follows }}</div>
      </div>

      <!-- <div class="quota">
        <div class="unit">Quota</div>
        <div class="value">123</div>
      </div> -->

      <div class="launchpad">
        <div class="unit">Launchpad</div>
        <div class="value" @click="toIDO">{{ idos }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { airdropfollows, idomine } from "@/api/controller";
export default {
  data() {
    return {
      Forte: true,
      follows: 0,
      idos: 0,
    };
  },
  watch: {
    "$store.state.address": {
      handler(newval) {
        if (newval) {
          this.identify(newval); // 更新Forte
          this.airdropfollows();
          this.idomine();
        }
      },
    },
  },
  methods: {
    async airdropfollows() {
      const res = await airdropfollows();
      if (res.code === 0) {
        this.follows = res.data.length || 0;
      }
    },
    async idomine() {
      const res = await idomine({
        pageIndex: this.pageIndex,
        pageSize: 1000,
      });
      if (res.code === 0) {
        this.idos = res.data.length || 0;
      }
    },
    identify(address) {
      if (address) {
        try {
          if (!sessionStorage.getItem("token")) {
            this.$store.commit({
              type: "setaddress",
              val: "",
            });
            this.Forte = false;
          } else {
            let str =
              address.substring(0, 6) +
              "......" +
              address.substring(address.length - 6, address.length);
            this.Forte = true;
            return str;
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          if (sessionStorage.getItem("token")) {
            this.$store.commit({
              type: "setaddress",
              val: sessionStorage.getItem("address"),
            });
            let str =
              address.substring(0, 10) + "......" + address.substring(37, 42);
            this.Forte = true;
            return str;
          } else {
            this.Forte = false;
          }
        } catch (error) {
          console.log(error);
        }
        console.log(this.Forte);
      }
    },
    async copy(value) {
      await this.$copyText(value);
      this.$notify.success(this.$t('copySuccess'));
    },

    Globaland() {
      try {
        if (!sessionStorage.getItem("token")) {
          console.log("广播到index.vue");
          // 广播到index.vue
          this.Event.$emit("landing");
          return false;
        } else {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    toFollow() {
      this.$router.push("/make-a-new");
    },
    toIDO() {
      this.$router.push("/attention");
    },
  },
};
</script>

<style lang="less" scoped>
.user-card {
  background: linear-gradient(0deg, #2d3138, #3a3f45);
  border-radius: 30px;
  padding: 20px;
  color: #f0f0ef;
  font-family: PingFang SC;
  text-align: center;

  .avator {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    padding: 20px;

    .line {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .outline {
      border: 1px solid rgba(33, 226, 169, 0.24);
      padding: 3px;
    }
    .innerline {
      border: 1px solid rgba(33, 226, 169, 0.79);
    }
    .image {
      position: relative;
      cursor: pointer;
      background: #22252c;
      box-shadow: 0px 4px 5px 0px rgba(21, 23, 28, 0.4);

      &:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        right: 14px;
        border-radius: 50%;
        bottom: 14px;
        background: #7b8087;
      }

      &.active::before {
        background: #1edfa6;
      }
    }
  }

  .username {
    font-size: 28px;
    padding: 30px 0;
    font-weight: bold;

    .copy {
      font-size: 18px;
      margin: 0 2px;
      cursor: pointer;
      &:hover {
        color: rgb(33, 226, 168);
      }
    }

    .not-login {
      margin-top: 20px;
      background: #3F444A;
      padding: 8px 10px;
      color: #7B8087;
      border-radius: 10px;
      cursor: pointer;
      margin-left: auto;
      margin-right: auto;
      font-size: 22px;
      font-weight: 600;

      &:hover {
        color: #f0f0f0;
        border-color: #41ffc7;
      }
    }
  }

  .usergrid {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .unit {
      padding: 10px 0;
      font-size: 14px;
      color: #7a8087;
    }
    .value {
      font-size: 18px;
      font-weight: bold;
      line-height: 2;
      cursor: pointer;

      &:hover {
        color: #41ffc7;
      }
    }
  }
}
</style>
