<template>
  <div class="home">
    <div class="explain">
      <div class="explain-grid-3--4">
        <!-- <div class="explain-title">
          <p style="white-space: nowrap">Track,Analyze and Discover</p>
          <p style="white-space: nowrap">blockchain project</p>
        </div> -->

        <!-- 三个点 -->
        <div class="banner">
          <!-- <div class="Three-points" v-if="banner.length > 1">
          <div
            v-for="(item, index) in banner"
            :key="index"
            :class="bannerCrount == index ? 'active' : ''"
            @mousemove="bannercut(index)"
          ></div>
        </div> -->
          <swiper
            ref="swiperRef"
            :options="{
              ...swiperOptions,
              autoplay: {
                delay: 3000,
                disableOnInteraction: false,
              },
            }"
            class="swiper-banners"
            @slideChange="bannerChange"
          >
            <swiper-slide v-for="item in banner" :key="item.id">
              <img
                class="banner-img"
                :src="item.picUrl"
                @click="openbanner(item.linkUrl)"
              />
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="user--graphical">
        <div class="user-card">
          <userCard />
        </div>

        <div class="graphical">
          <charts width="100%" height="100%" :chartData="chartData" />
        </div>
      </div>
      <div class="explain-grid-1--4 recommendations" v-if="objdata.length > 0">
        <div class="control">
          <div class="title">
            <svg-icon iconClass="news" className="icon"></svg-icon>
            <span class="text">{{ $t("news") }}</span>
          </div>
          <div class="more" @click="btn_more">More</div>
        </div>
        <swiper
          :options="{
            ...swiperOptions,
            autoplay: {
              delay: 10000,
              spaceBetween: 0,
              disableOnInteraction: false,
            },
          }"
          class="swiper-news"
          @slideChange="change"
        >
          <swiper-slide v-for="item in objdata" :key="item.id">
            <div class="list-page">
              <div
                class="box1"
                v-for="(info, index) in item"
                :key="index"
                @click="openwidn(info.websiteUrl)"
              >
                <img :src="info.image" alt="" />
                <div class="continaer">
                  <!-- <p class="time">
                    {{ info.yeaer }}
                    <span>{{ info.mounty + "." + info.today }}</span>
                  </p> -->
                  <p class="title">
                    {{ info.headline }}
                  </p>
                  <p class="describe" v-if="info.subheadline">
                    {{ info.subheadline }}
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="home-title">
      <svg-icon iconClass="project" className="primary-text-color"></svg-icon>
      <span class="text">{{ $t("project") }} </span>
    </div>
    <!-- 导航 -->

    <el-tabs
      v-model="activeIndex"
      class="el-menu-demo"
      background-color="#22252C"
      text-color="#7B8087"
      active-text-color="#fff"
      @tab-click="handleSelect"
    >
      <el-tab-pane label="DeFi" name="DeFi"></el-tab-pane>
      <el-tab-pane label="CeFi" name="CeFi"></el-tab-pane>
      <el-tab-pane label="Infrastructure" name="Infrastructure"></el-tab-pane>
      <el-tab-pane label="Web3" name="Web3"></el-tab-pane>
      <el-tab-pane label="NFTs" name="NFTs"></el-tab-pane>
      <!-- <div class="icon-tile">
        <img src="@/static/images/time.png" alt="" srcset="" />
        <p>Time leaderboard</p>
      </div> -->
    </el-tabs>

    <!--  -->
    <ulitem
      :categoryALL="categoryALL"
      :loading="loading"
      :skeleton-num="8"
    ></ulitem>

    <!--  -->
  </div>
</template>

<script>
import ulitem from "@/components/ulitem";
import { homeBanner, projects, newslist, projectlist } from "@/api/require.js";
import userCard from "@/components/userCard";

import charts from "@/components/charts/index.vue";

export default {
  components: {
    charts,
    userCard,
    ulitem,
  },
  data() {
    return {
      activeIndex: "DeFi",
      crount: 0,
      bannerCrount: 0,
      banner: [],
      objdata: [],
      categoryALL: [],
      loading: true,
      swiperOptions: {
        grabCursor: true,
        spaceBetween: 50,
        centeredSlides: true,
        loop: true,
      },
      chartData: {},
    };
  },
  created() {
    this.homeBanner();
    this.newslist();
    this.projects();
    this.initChartData();
  },
  computed: {
    swiper() {
      return this.$refs.swiperRef.$swiper;
    },
  },
  methods: {
    openbanner(url) {
      if (url) {
        window.open(url);
      }
    },

    bannercut(index) {
      // this.$refs.bannerCarousel.setActiveItem(index);
      this.swiper.slideTo(index + 1, 1000, true);
    },

    journalism(index) {
      this.$refs.carousel.setActiveItem(index);
    },

    openwidn(url) {
      window.open(url);
    },

    timeDisposal(val) {
      let time = val.split(" ")[0];
      return {
        yeaer: time.split("-")[0],
        mounty: time.split("-")[1],
        today: time.split("-")[2],
      };
    },
    async initChartData() {
      const res = await projectlist({
        amount: {
          //融资金额范围
          end: 10000000000, //结束值
          start: 50000000, //开始值
        },
        category: [
          //需要匹配的分类列表
        ],
        investors: [
          //投资机构
        ],
        key: "",
        pageIndex: 1,
        pageSize: 6,
        orderBy: "amount",
        sort: "asc",
      });
      const xAxis = res.data.list.map((r) => r.project);
      const yAxis = res.data.list.map((r) => r.amount);
      this.chartData = { xAxis, yAxis };
    },

    async newslist(headline, pageIndex, pageSize) {
      let res = await newslist(
        (headline = ""),
        (pageIndex = 1),
        (pageSize = 6)
      );
      if (res.code === 0) {
        res.data.list.forEach((element, index) => {
          let obj = [];
          for (let i = index + index; i < index + index + 3; i++) {
            // 有数据
            if (res.data.list[i]) {
              let time = this.timeDisposal(res.data.list[i].publishDate);
              res.data.list[i].yeaer = time.yeaer;
              res.data.list[i].mounty = time.mounty;
              res.data.list[i].today = time.today;

              obj.push(res.data.list[i]);
            }
          }
          if (obj.length > 0) {
            this.objdata.push(obj);
          }
        });
        try {
          this.objdata.splice(2);
        } catch (error) {
          console.log(error);
        }
      }
    },

    async homeBanner() {
      let res = await homeBanner();
      if (res.code == 0) {
        this.banner = res.data;
      } else {
      }
    },

    async projects(size = 8, category = "") {
      let res = await projects(size, category);
      if (res.code === 0) {
        // 把时间格式转为英文
        // console.log('把时间格式转为英文')
        res.data.forEach((item) => {
          item["enchangeTime"] = this.$tool.conversion(item.changeTime);
        });
        this.categoryALL = res.data;
        this.loading = false;
      }
    },

    handleSelect() {
      this.projects(8, this.activeIndex);
      this.categoryALL = [];
      this.loading = true;
    },
    btn_more() {
      this.$router.push("/news");
    },
    change({ realIndex }) {
      this.crount = realIndex;
    },
    bannerChange({ realIndex }) {
      this.bannerCrount = realIndex;
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  /deep/.recommendations {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #2d3138, #3a3f45);
    border-radius: 30px;
    overflow: hidden;
    box-sizing: border-box;

    .control {
      color: #7b8087;
      padding: 15px 20px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .title {
        font-family: PigFang FC;
        font-size: 24px;
        font-weight: bold;
        color: #f0f0ef;

        .icon {
          color: #1edfa6;
        }
        .text {
          margin: 0 10px;
        }
      }

      .more {
        cursor: pointer;

        &:hover {
          color: #1edfa6;
        }
      }

      .opt-for {
        display: flex;
        align-items: center;
        div {
          margin: 10px 5px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #22c29d;
          opacity: 0.2;
          cursor: pointer;
        }
        .active {
          background: #1edfa6;
          opacity: 1;
        }
      }
      p {
        font-size: 12px;
        color: #1edfa6;
      }
      img {
        &:active {
          opacity: 0.7;
        }
        width: 73px;
        height: 73px;
        border-radius: 50px;
        cursor: pointer;
      }
    }
    .list-page {
      width: 100%;
      padding: 10px 0;
      .box1 {
        padding: 10px 20px;
        &:active {
          opacity: 0.7;
        }
        &:hover {
          opacity: 0.7;
        }
        cursor: pointer;
        border-radius: 30px;
        img {
          width: 100%;
          border-radius: 15px;
          height: 160px;
          object-fit: cover;
        }
        .continaer {
          margin-top: 10px;
          overflow: hidden;

          .describe {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 400;
            color: #909399;
            overflow: hidden;
            text-overflow: ellipsis; // text-overflow css3的属性，当文本溢出时，显示省略号

            display: -webkit-box;

            -webkit-line-clamp: 2; // 设置两行文字溢出

            -webkit-box-orient: vertical;

            overflow: hidden;
          }
          .title {
            font-size: 14px;
            font-weight: 400;
            color: #f0f0ef;
            word-break: break-word;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .time {
            font-size: 14px;
            color: #7e8ab6;
            span {
              font-size: 10px;
            }
          }
        }
      }
    }
    .el-carousel {
      width: 100%;
    }
    .el-carousel__container {
      height: 160px !important;
    }
    .el-carousel__item {
      display: flex;
      justify-content: space-between;
    }
  }
  /deep/.el-tabs__nav {
    padding: 0 15px;
  }
  /deep/.el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #30343c;
  }
  /deep/.el-tabs__item {
    color: #7b8087;

    &.is-active {
      color: #f0f0ef;
    }
    &:hover {
      color: #f0f0ef;
    }
  }
  /deep/.el-tabs__active-bar {
    padding: 0 15px;
    background-color: #1edfa6;
  }
  /deep/.el-menu-demo {
    margin-top: 24px;
    margin-bottom: 30px;
    .icon-tile {
      width: 120px;
      display: flex;
      align-items: center;
      height: 60px;
      float: right;
      img {
        width: 16px;
        height: 16px;
      }
      p {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 11px;
        color: #909399;
        margin-left: 10px;
      }
    }
  }
  /deep/.explain {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    .explain-grid-3--4 {
      grid-area: ~"1 / 1 / 2 / 5";
    }
    .explain-grid-1--4 {
      grid-area: ~"1 / 5 / 3 / 6";
    }

    .user--graphical {
      grid-area: ~"2 / 1 / 3 / 5";

      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;

      .user-card {
        grid-area: ~"1 / 1 / 2 / 2";
      }
      .graphical {
        grid-area: ~"1 / 2 / 2 / 5";
      }
    }

    .swiper-container {
      width: 100%;
      border-radius: 10px;
      .banner-img {
        width: 100%;
        border-radius: 30px;
        cursor: pointer;
        &:active {
          opacity: 0.7;
        }
      }
    }
    .banner {
      width: 100%;
      display: flex;
      align-items: center;
      height: 360px;

      .swiper-container,
      .swiper-container .banner-img {
        height: 360px;
      }

      .Three-points {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        div {
          cursor: pointer;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin: 5px 0;
          background: #22252c;
        }
        .active {
          background: #1edfa6;
        }
      }
    }
    .explain-title {
      p {
        font-size: 22px;
        font-weight: bold;
        color: #7b8087;
      }
    }
  }

  .home-title {
    margin: 20px 0;
    font-size: 24px;
    color: #f0f0ef;

    .text {
      margin: 0 10px;
      font-family: PigFang FC;
    }
  }
}

@media screen and (max-width: 1920px) {
  .home {
    /deep/.explain {
      grid-template-columns: repeat(4, 1fr);
      .explain-grid-3--4 {
        grid-area: ~"1 / 1 / 2 / 4";
      }
      .explain-grid-1--4 {
        grid-area: ~"1 / 4 / 3 / 5";
      }

      .user--graphical {
        grid-area: ~"2 / 1 / 3 / 4";
      }
    }
  }
}

@media screen and (max-width: 876px) {
  .home {
    /deep/.explain {
      display: block;
      .explain-grid-3--4 {
        width: 100%;
        margin: 20px 0;
      }
      .explain-grid-1--4 {
        width: 100%;
        margin: 20px 0;
      }

      .user--graphical {
        width: 100%;
        margin: 20px 0;
        display: block;

        .graphical {
          display: none;
        }
      }
    }
  }
}
.isMobile {
  .explain .banner .swiper-container .banner-img {
    object-fit: cover;
    object-position: left;
  }
}
</style>
