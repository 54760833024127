var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"explain"},[_c('div',{staticClass:"explain-grid-3--4"},[_c('div',{staticClass:"banner"},[_c('swiper',{ref:"swiperRef",staticClass:"swiper-banners",attrs:{"options":Object.assign({}, _vm.swiperOptions,
            {autoplay: {
              delay: 3000,
              disableOnInteraction: false,
            }})},on:{"slideChange":_vm.bannerChange}},_vm._l((_vm.banner),function(item){return _c('swiper-slide',{key:item.id},[_c('img',{staticClass:"banner-img",attrs:{"src":item.picUrl},on:{"click":function($event){return _vm.openbanner(item.linkUrl)}}})])}),1)],1)]),_c('div',{staticClass:"user--graphical"},[_c('div',{staticClass:"user-card"},[_c('userCard')],1),_c('div',{staticClass:"graphical"},[_c('charts',{attrs:{"width":"100%","height":"100%","chartData":_vm.chartData}})],1)]),(_vm.objdata.length > 0)?_c('div',{staticClass:"explain-grid-1--4 recommendations"},[_c('div',{staticClass:"control"},[_c('div',{staticClass:"title"},[_c('svg-icon',{attrs:{"iconClass":"news","className":"icon"}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("news")))])],1),_c('div',{staticClass:"more",on:{"click":_vm.btn_more}},[_vm._v("More")])]),_c('swiper',{staticClass:"swiper-news",attrs:{"options":Object.assign({}, _vm.swiperOptions,
          {autoplay: {
            delay: 10000,
            spaceBetween: 0,
            disableOnInteraction: false,
          }})},on:{"slideChange":_vm.change}},_vm._l((_vm.objdata),function(item){return _c('swiper-slide',{key:item.id},[_c('div',{staticClass:"list-page"},_vm._l((item),function(info,index){return _c('div',{key:index,staticClass:"box1",on:{"click":function($event){return _vm.openwidn(info.websiteUrl)}}},[_c('img',{attrs:{"src":info.image,"alt":""}}),_c('div',{staticClass:"continaer"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(info.headline)+" ")]),(info.subheadline)?_c('p',{staticClass:"describe"},[_vm._v(" "+_vm._s(info.subheadline)+" ")]):_vm._e()])])}),0)])}),1)],1):_vm._e()]),_c('div',{staticClass:"home-title"},[_c('svg-icon',{attrs:{"iconClass":"project","className":"primary-text-color"}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("project"))+" ")])],1),_c('el-tabs',{staticClass:"el-menu-demo",attrs:{"background-color":"#22252C","text-color":"#7B8087","active-text-color":"#fff"},on:{"tab-click":_vm.handleSelect},model:{value:(_vm.activeIndex),callback:function ($$v) {_vm.activeIndex=$$v},expression:"activeIndex"}},[_c('el-tab-pane',{attrs:{"label":"DeFi","name":"DeFi"}}),_c('el-tab-pane',{attrs:{"label":"CeFi","name":"CeFi"}}),_c('el-tab-pane',{attrs:{"label":"Infrastructure","name":"Infrastructure"}}),_c('el-tab-pane',{attrs:{"label":"Web3","name":"Web3"}}),_c('el-tab-pane',{attrs:{"label":"NFTs","name":"NFTs"}})],1),_c('ulitem',{attrs:{"categoryALL":_vm.categoryALL,"loading":_vm.loading,"skeleton-num":8}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }