<template>
  <div :style="{ height: height, width: width }">
    <div
      class="skeleton"
      v-if="loading"
      :style="{ height: '100%', width: '100%' }"
    >
      <div class="chart-skeleton">
        <div
          v-for="item in 6"
          :key="item"
          :style="{
            height: (item > 3 ? 100 - item * 10 : item * 30) + '%',
          }"
        >
          <div class="skeleton--item chart-item"></div>
        </div>
      </div>
    </div>
    <div
      :id="id"
      :class="className"
      :style="{ height: '100%', width: '100%' }"
    />
  </div>
</template>

<script>
import * as echarts from "echarts";
import resize from "./mixins/resize";
import { formatNumber } from "@/utils/index";

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    id: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "200px",
    },
    height: {
      type: String,
      default: "200px",
    },
    chartData: {
      type: Object,
      default: () => ({
        xAxis: [],
        yAxis: [],
      }),
    },
  },
  data() {
    return {
      loading: true,
      chart: null,
    };
  },
  computed: {
    maxData() {
      return Math.max(...this.chartData.yAxis);
    },
    getxAxis() {
      return this.chartData.xAxis;
    },
    getyAxis() {
      return this.chartData.yAxis;
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  watch: {
    chartData: {
      handler(newval) {
        this.loading = false;
        this.initChart();
      },
    },
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id));
      const xAxis = this.getxAxis;
      const yAxis = this.getyAxis;
      const maxData = this.maxData;

      this.chart.setOption({
        backgroundColor: "transparent",
        darkMode: true,
        // title: {
        //   top: 20,
        //   text: "Requests",
        //   textStyle: {
        //     fontWeight: "normal",
        //     fontSize: 16,
        //     color: "#F1F1F3",
        //   },
        //   left: "1%",
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "#57617B",
            },
          },
        },
        grid: {
          top: 20,
          left: 60,
          right: 60,
          bottom: 20,
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              show: false,
            },
            data: xAxis,
            axisLabel: {
              color: "#848891",
              margin: 10,
              rotate: 12,
            },
            labelLine: {
              lineStyle: {
                color: "#548897",
              },
            },
          },
        ],
        yAxis: [
          {
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#848891",
              },
            },
            axisLabel: {
              show: false,
              textStyle: {
                fontSize: 16,
              },
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                dashOffset: 10,
                color: "#474B51",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            smooth: true,
            symbol: "circle",
            symbolSize: 1,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 1,
              },
            },
            barMaxWidth: 12,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(137, 189, 27, 0.3)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(137, 189, 27, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 10,
                emphasis: {
                  color: "#333",
                },
              },
            },
            label: {
              show: true,
              position: "top",
              color: "inherit",
              fontSize: 14,
              formatter: ({ value }) => {
                return formatNumber(value);
              },
            },
            itemStyle: {
              normal: {
                color: function ({ value }) {
                  if (value === maxData) {
                    return "#1edfa6";
                  }
                  return "#848891";
                },
                borderWidth: 0,
                borderRadius: 8,
              },
              emphasis: {
                color: "#1edfa6",
              },
            },
            data: yAxis,
          },
        ],
      });
    },
  },
};
</script>
<style lang="less" scoped>
.chart-skeleton {
  position: relative;
  margin-top: -40px;
  padding: 0 60px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .chart-item {
    position: relative;
    height: 100%;
    width: 100px;
  }

  .short-text {
    width: 100px;
    height: 24px;
    position: absolute;
    bottom: 0;
    left: -12px;
  }
}

.skeleton .skeleton--item {
  background: linear-gradient(
    90deg,
    rgba(50, 53, 60, 1) 25%,
    #22252c 37%,
    RGBA(56, 61, 67, 1) 63%
  );
  background-size: 400% 100%;
  -webkit-animation: el-skeleton-loading 1.3s ease infinite;
  animation: el-skeleton-loading 1.3s ease infinite;
}
</style>
